import { configureStore } from '@reduxjs/toolkit'
import appReducer from "./appSlice";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistThemeConfig = {
  key: `di-${window.location.hostname === process.env.REACT_APP_WIDGET_DOMAIN ? "klient" : "agent"}-theme`,
  storage,
  whitelist: ["theme", "fontSize", "isMaximized"]
}

export const store = configureStore({   
    reducer: {
        app: persistReducer(persistThemeConfig, appReducer)
    },
  // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
    }),
})

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
