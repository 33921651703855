import {setTheme,} from "../tools/setTheme";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {themeSelector} from "../redux/selectors";

export const useSetTheme = () => {
  
  const theme = useSelector(themeSelector);

  useEffect(() => {
    setTheme(theme);
  }, [theme]);
  
}