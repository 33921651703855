export const Header = () => {
 
  return (
    <header>
      <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">Sklep AGD</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/lodowki">Lodówki</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/pralki">Pralki</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/zmywarki">Zmywarki</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/kontakt">Kontakt</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  </header>
  );
  
}