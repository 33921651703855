import {createSelector} from "@reduxjs/toolkit";
import type {RootState} from "../store";

const app = (state:RootState) => state.app;

export const licenseSelector = createSelector([app], app => app.license );

export const userTypeSelector = createSelector([app], app => app.userType );

export const themeSelector = createSelector([app], app => app.theme);

export const fontSizeSelector = createSelector([app], app => app.fontSize);

export const isMaximized = createSelector([app], app => app.isMaximized);