import {Footer} from "../Footer";
import {Header} from "../Header";
import {Helmet} from "react-helmet";

export const PrivacyPolicyPage = () => {
  
  return (
    <div className="container">
      <Helmet>
        <title>Sklep AGD - Polityka prywatności</title>
      </Helmet>
    <Header />  
      
    <main className="mt-4">
      <h2>Polityka prywatności</h2>
      <p className="my-4">
        W prawdziwym sklepie znajdziesz tutaj politykę prywatności, ale tutaj nic nie ma bo to tylko sklep testowy.    
      </p>
    </main>
      
    <Footer />
      
  </div>
  );
  
}