import {themes} from "../themes/themes";
import type {Theme} from "di-chat-model";

const root = document.documentElement;

export const setTheme = (theme:Theme) => {
  
  const themeToSet = themes[theme]; 
  
  Object.keys(themeToSet).forEach( (varName:string) => {
    root.style.setProperty(varName, themeToSet[varName]);
  });
  
}