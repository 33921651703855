export const Footer = () => {
  
  return (
    <footer tabIndex={1}>
      <nav className="navbar navbar-expand-lg bg-light">
        <ul className="navbar-nav">
          <li className="nav-item"><a className="nav-link" href="/regulamin">Regulamin</a></li>
          <li className="nav-item"><a className="nav-link" href="/polityka-prywatnosci">Polityka prywatności</a></li>
          <li className="nav-item"><a className="nav-link" href="/kontakt">Kontakt</a></li>
          <li className="nav-item"><a className="nav-link" href="/chat">Porozmawiaj na czacie</a></li>
        </ul>
      </nav>
    </footer>
  );
  
}