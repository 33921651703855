import {Chat} from "./Chat";
import {useInitializeChat} from "../../hooks/useInitializeChat";

export const ChatContainer = () => {
    
    const initialized = useInitializeChat();
 
    if ( initialized ) {
        return (
          <Chat/>
        );
    } else {
        return null;
    }
    
}