import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {fontSizeSelector} from "../redux/selectors";
import {setFontSize} from "../redux/appSlice";

// TODO: Czy nie lepiej zrobić to jako middleware reduxa
// zamiast robić hook, z którego niego wywołuję akcję reduxa?

const root = document.documentElement;
const fontSizeVar = "--cs-main-container-font-size";

const getCurrentFontSize = () => parseFloat(getComputedStyle(root).getPropertyValue(fontSizeVar));

export const useSetFontSize = (defaultFontSize:string, step:number) => {
  
  const dispatch = useDispatch();
  const fontSize = useSelector(fontSizeSelector)
  
  useEffect(() => {
      root.style.setProperty(fontSizeVar, fontSize);
  }, [fontSize]);
  
  const fontDefault = () => dispatch(setFontSize(defaultFontSize));
  
  const fontUp = () => dispatch(setFontSize(`${getCurrentFontSize() + step}rem`));
  
  const fontDown = () => dispatch(setFontSize(`${getCurrentFontSize() - step}rem`));
  
  return {
    fontSize,
    fontSizeFloat: parseFloat(fontSize),
    fontUp,
    fontDefault,
    fontDown
  };
  
}