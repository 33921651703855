import {KeyboardEvent, FocusEvent, useRef} from "react";
import {ChatMessageId} from "@chatscope/use-chat";

const focusedClassName = "focused";
// TODO: Tutaj argumentem powinna być referencja do listy wiadomości
export const useMessageListKeyboardNavigation = () => {
  
  // Referencja do ostatnio focusowanej wiadomości
  // Służy do porównania z aktualną ostatnią wiadomością na liście
  // żeby wiedzieć jaki tabindex jej renderować
  const lastFocusedMessage = useRef<ChatMessageId | null>(null);
  
  // TODO: Wejście na listę wiadomości z góry.... na co powinno wejść?
  const onMessageKeyDown = (evt:KeyboardEvent<HTMLDivElement>) => {
    
    const key = evt.key;
    const currentMessage = evt.currentTarget;
    
    if ( key === "ArrowDown" ) {
      
      const next = currentMessage.nextElementSibling;
      if ( next && ( next.hasAttribute("data-cs-message") || next.hasAttribute("data-separator") ) ) {
        
        next.querySelector<HTMLDivElement>("[data-focusable-content]")?.focus();

      } else {
        // Nie ma nastepnego elementu, to szukamy następnej grupy
        // Jeżeli jest grupa, to szukam pierwszego elementu, a w nim focusable content i go focusuję
        const nextGroup = currentMessage.parentElement?.parentElement?.parentElement?.nextElementSibling;
          
        if ( nextGroup ) {
          if (nextGroup.hasAttribute("data-system-message")) {
            nextGroup.querySelector<HTMLDivElement>("[data-separator]:first-child [data-focusable-content]")?.focus();
          } else {
            const focusable = nextGroup.querySelector<HTMLDivElement>("[data-cs-message]:first-child [data-focusable-content]")
            if (focusable) {
              focusable.focus();
            } else {
              // Znaleziony element może nie być już grupą
              // Wtedy szukamy jeszcze typing indicatora
              document.querySelector<HTMLDivElement>("#message-list-typing-indicator [data-typing-indicator-content]")?.focus();
            }
          }
          
        } 
      }

    } else if ( key === "ArrowUp" ) {
      
      // Szukam poprzedniego elementu, który ma data-focusable-content
      // Właściwie to wystarczy, że znajdę poprzednią wiadomość
      // i w niej data-focusable-content
      const prev = currentMessage.previousElementSibling;
      if (prev && (prev.hasAttribute("data-cs-message") || prev.hasAttribute("data-separator") ) ) {
        prev.querySelector<HTMLDivElement>("[data-focusable-content]")?.focus();
      } else {

        // Nie ma poprzedniego elementu, to szukamy poprzedniej grupy
        // Jeżeli jest grupa, to szukam ostatniego elementu
        const prevGroup = currentMessage.parentElement?.parentElement?.parentElement?.previousElementSibling;
        if (prevGroup) {
          // Czy to jest wiadomość systemowa?
          // Jeżeli tak to szukamy inaczej, bo element separator ma inną strukturę niż message 
          if (prevGroup.hasAttribute("data-system-message")) {
            prevGroup.querySelector<HTMLDivElement>("[data-separator]:last-child [data-focusable-content]")?.focus();
          } else {
            prevGroup.querySelector<HTMLDivElement>("[data-cs-message]:last-child [data-focusable-content]")?.focus();
          }
        } else {
          if ( currentMessage.id === "message-list-typing-indicator" ) {
            // Jeżeli jestem aktualnie na typing indicatorze
            // To szukam ostatniej wiadomości w ostatniej grupie
            const lastGroup = document.querySelector<HTMLDivElement>("[data-cs-message-group]:last-of-type");
            if (lastGroup) {
              if (lastGroup.hasAttribute("data-system-message")) {
                lastGroup.querySelector<HTMLDivElement>("[data-separator]:last-child [data-focusable-content]")?.focus();
              } else {
                lastGroup.querySelector<HTMLDivElement>("[data-cs-message]:last-child [data-focusable-content]")?.focus();
              }
            }
            
          }
        }
      }
    }
  }
  
  const onCellFocus = (evt:FocusEvent<HTMLDivElement | HTMLSpanElement>, messageId: ChatMessageId) => {
    
    const focused = evt.currentTarget;
    
    // tabindex i klasę outline tylko jeżeli wszedłem klawiaturą
    if ( focused.matches(":focus-visible") ) {

      focused.setAttribute("tabindex", "0");

      // focusowany element to może być wiadomość albo separator, więc do obydwu typów dodaję klasę
      focused.closest<HTMLDivElement>("[data-cs-message]")?.classList.add(focusedClassName);
      focused.closest<HTMLDivElement>("[data-separator]")?.classList.add(focusedClassName);
      lastFocusedMessage.current = messageId;
      
    }
    
  }
  
  const onCellBlur = (evt:FocusEvent<HTMLDivElement>) => {
    
    const blurred = evt.currentTarget;
    
    // W related target jest referencja do elementu, do którego przeskoczył focus,
    // jeżeli ten element nie jest wiadomością, to przyjmuję, że wyszedłem z okna wiadomości, więc nie usuwam tabindex
    // To powoduje, że po ponownym wejściu do okna wiadomości wrócę na wiadomość, z której wyszedłem
    const related = evt.relatedTarget;

    // Ponieważ w tym samym cyklu related nie ma jeszcze ustawionego focus-visible
    // to nie wiem, czy to był blur z myszki czy klawiatury
    // Dlatego sprawdzenie jest w setTimeout
    setTimeout(() => {
      if ( related && related.hasAttribute("data-focusable-content") && related.matches(":focus-visible")) {
        blurred.setAttribute("tabindex", "-1");
      }
    }, 1);
    
    blurred.closest<HTMLDivElement>("[data-cs-message]")?.classList.remove(focusedClassName);
    blurred.closest<HTMLDivElement>("[data-separator]")?.classList.remove(focusedClassName);
    
  }
  
  return {onMessageKeyDown, onCellFocus, onCellBlur, lastFocusedMessage};
  
}