import {ConversationHeader } from "@chatscope/chat-ui-kit-react";
import "./widget-conversation-header.scss";
import {A11YToolbox} from "./A11YToolbox";
import {useGetParticipant} from "../hooks/useGetParticipant";
import {useChat} from "@chatscope/use-chat";
import {useMemo} from "react";

interface WidgetConversationHeaderProps {
 as: string;
 onWriteClick?: () => void;
 onGoToConversationClick?: () => void;
}
export const WidgetConversationHeader = ({onWriteClick, onGoToConversationClick}:WidgetConversationHeaderProps ) => {

    const {activeConversation} = useChat();
    const { name, avatar } = useGetParticipant(activeConversation, false);
    
    const headerText = useMemo(() => name ?
      <><span className="visually-hidden">Rozmowa z </span>{name}</> 
      : <span className="visually-hidden">Rozmowa ze sklepem internetowym</span>,[name]);
    
    return (
      <ConversationHeader className="wc-header">
        {avatar}
        <ConversationHeader.Content userName={
          <h1 id="remote-user-name" className="wc-header__user-name m-0 p-0 fw-bold">{headerText}</h1>
        }
        >
          
        </ConversationHeader.Content>
        <ConversationHeader.Actions>
            <button className="btn visually-hidden-focusable me-2 wc-header__btn" onClick={onWriteClick}>Napisz wiadomość</button>               
            <button className="btn visually-hidden-focusable me-2 wc-header__btn" onClick={onGoToConversationClick}>Przejdź do okna wiadomości</button>
            <A11YToolbox />
        </ConversationHeader.Actions>
      </ConversationHeader>
    );
}
