import "../start-page.scss";
import {Header} from "../Header";
import {Footer} from "../Footer";
import {Helmet} from "react-helmet";

export const StartPage = () => {
    
    return (
      <div className="container">
        <Helmet>
          <title>Sklep AGD - Strona główna</title>
        </Helmet>
        <Header />
        
        <main className="mt-4">
          <p>Witamy w sklepie AGD</p>
          <p>Nasz sklep oferuje szeroki wybór sprzętu AGD.</p>
          <p>Specjalizujemy się w sprzedaży zarówno małego jak i dużego AGD.</p>
          <p>Nasi doradcy zawsze służą profecjonalną pomocą przy wyborze sprzętu.</p>
        </main>

        <Footer />
        
      </div>
    );
    
} 