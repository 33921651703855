import {Link} from "react-router-dom";
import {Footer} from "../Footer";
import {Header} from "../Header";
import {Helmet} from "react-helmet";

export const ContactPage = () => {
  
  return (
    <div className="container">
      <Helmet>
        <title>Sklep AGD - Kontakt</title>
      </Helmet>
      <Header />  
      
      <main className="mt-4">
        <h2>Informacje kontaktowe</h2>
        <div className="p-4">
          Adres: ul. Stoicka 12<br />
          Telefon: 123 456 789<br />
          Czat online: <Link to="/chat" className="start-page">Rozpocznij czat</Link>
        </div>
      </main>
      
    <Footer />
      
  </div>
  );
  
}